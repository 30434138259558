
  import { Component, Prop } from 'vue-property-decorator'
  import { Appraisal } from '@/entities/purchase'
  import { fixPrice, isValidNumber } from '@genio/automotive-console/src/utils/general'
  import { AppraisalView } from '@/components/forms/view/AppraisalView'

@Component({
  methods: { fixPrice },
})
  export default class AppraisalResume extends AppraisalView {
  @Prop({ type: [String, Number, Object, Array] }) value!: any

  // Methods

  // Getters
  get appraisal (): Appraisal {
    const { value } = this

    return value as Appraisal
  }

  get lastAppraisalResponse () {
    const { appraisal } = this

    return appraisal.lastAppraisalResponse?.value
  }

  get lastAppraisalAppealResponse () {
    const { appraisal } = this

    return appraisal.appeal?.amount
  }

  get agreement () {
    const { appraisal } = this

    return appraisal.agreement?.value
  }

  get lastAppraisalResponseConsignment () {
    const { appraisal } = this

    return appraisal.appraisalConsignment?.amount
  }

  get lastAppealResponseConsignment () {
    const { appraisal } = this

    return appraisal.responseConsignment?.amount
  }

  get agreementConsignment () {
    const { appraisal } = this

    return appraisal.agreementConsignment?.value
  }

  get isConsignment () {
    const { displayConsignment, lastAppraisalResponseConsignment, lastAppealResponseConsignment } = this

    const isAppeal = lastAppealResponseConsignment && isValidNumber(lastAppealResponseConsignment)
    const isResponse = lastAppraisalResponseConsignment && isValidNumber(lastAppraisalResponseConsignment)

    return displayConsignment && (isAppeal || isResponse)
  }

  get displayPurchase () {
    const { appraisal, maxMileage } = this

    return this.displayPurchaseFields(appraisal.kms, maxMileage)
  }
  }
